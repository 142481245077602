




















































import { defineComponent, computed } from '@vue/composition-api';
import flatMap from 'lodash/flatMap';
import forEach from 'lodash/forEach';
import merge from 'lodash/merge';
import DataSchema from '../@types/data-schema';

export default defineComponent({
  props: {
    data: {
      required: true,
      type: Object as () => DataSchema,
    },
  },
  setup(props) {
    const { dataSet, dataLabels, title } = props.data;

    const hasDataSet = computed(() => Object.keys(dataSet).length > 0);
    const hasTableHeaderX = computed(() => Object.keys(dataLabels.x).length > 0);
    const hasTableHeaderY = computed(() => Object.keys(dataLabels.y).length > 0);
    const rowData: any = {};


    if (Object.keys(dataLabels.y).length > 0) {
      forEach(dataLabels.y, (label, labelKey) => {
        Object.assign(rowData, { [labelKey]: [label] });
      });
    }
    flatMap(dataSet, dataItem => {
      forEach(dataItem, (value, key) => {
        if (!rowData[key]) {
          Object.assign(rowData, { [key]: [] });
        }
        rowData[key].push(dataItem[key]);
      });
    });

    return {
      hasDataSet,
      hasTableHeaderX,
      hasTableHeaderY,
      rowData,
      dataSet,
      dataLabels,
      title,
    };
  },
});
